/* Clean up the lists styles */
ul.accordion {
    list-style: none;
}

    /* Hide the radio buttons */
    /* These are what allow us to toggle content panes */
    ul.accordion [role='heading'] input[data-expander] {
        display: none;
    }

    /* Give each content pane some styles */
    ul.accordion li {
        background-color: none;
    }

    /* Make the main tab look more clickable */
    ul.accordion label {
        margin-bottom: 0;
        margin-top: 0;
        display: block;
        padding: 0;
    }

        ul.accordion label:hover {
            cursor: pointer;
            background: #FFF;
        }

    ul.accordion input + div.content {
        margin-bottom: 5px;
    }

    ul.accordion table.tc2:hover {
        color: #113A5D;
    }

    ul.accordion td.destination:hover {
        /*padding-left: 1em;*/
    }

    /* Set up the div that will show and hide */
    ul.accordion div.content {
        overflow: hidden;
        padding: 0;
        display: block;
    }
